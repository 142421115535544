import productParser from "./parsers/product/parse-to-product";
import { data as _fakeProducts } from "./dummy-data/_fake.products";
// import productsParser from "./parsers/parse-to-products";

const ENDPOINT = "products";
const baseUrl = window._env_.REACT_APP_EDDRESS_ADDRESS


const getAll =
  ({ client, authHeader }) =>
  async () => {
    // return new Promise((resolve, reject) => {
    //   const parsedData = _fakeProducts.map(productParser.parseToProduct);
    //   resolve(parsedData);
    //   reject(new Error("Error"));
    // });

    const response = await client.get(`/${ENDPOINT}`, {
      headers: authHeader(),
    });

    const products = response.data.map(productParser.parseToProduct);

    return products;
  };

const getAllByStoreId =
  ({ client, authHeader }) =>
  async ({ storeId, storeCountry,accessToken }) => {
    // return new Promise((resolve, reject) => {
    //   const parsedData = _fakeProducts.map(productParser.parseToProduct);
    //   resolve(parsedData);
    //   reject(new Error("Error"));
    // });

    const response = await client.get(`/stores/${storeCountry}/${storeId}/${ENDPOINT}`, {
      headers: authHeader(accessToken),
    });

    const products = response.data.items.map(productParser.parseToProduct);

    return products;
  };

  const searchProduct = ({ client }) => {
    return async ({ query, page, storeId, tenantUid }) => {
      const response = await client.post(
        `${baseUrl}/v1/api/searchengine/public/${tenantUid}/search`,
        {
          query,
          page,
          storeId,
          tenantUid
        }
      );
      return response.data;
    };
  };
  
  

// const get = ({ client, authHeader }) => async (id) => {
//   const response = await client.get(`/${ENDPOINT}/${id}`, {
//     headers: authHeader(),
//   });

//   const product = productParser.parseToProduct(response.data);

//   return product;
// };

// const create = ({ client, authHeader }) => (data) => {
//   return client.post(`/${ENDPOINT}`, data, { headers: authHeader() });
// };

// const update = ({ client, authHeader }) => (id, data) => {
//   return client.put(`/${ENDPOINT}/${id}`, data, { headers: authHeader() });
// };

// const remove = ({ client, authHeader }) => (id) => {
//   return client.delete(`/${ENDPOINT}/${id}`, { headers: authHeader() });
// };

// const removeAll = ({ client, authHeader }) => () => {
//   return client.delete(`/${ENDPOINT}`, { headers: authHeader() });
// };

// const unremove = ({ client, authHeader }) => (id) => {
//   return client.put(`/${ENDPOINT}/${id}/undelete`, null, {
//     headers: authHeader(),
//   });
// };

export default (client, authHeader) =>
  class ProductService {
    getAll = getAll({ client, authHeader });
    getAllByStoreId = getAllByStoreId({ client, authHeader });
    searchProduct= searchProduct({client,authHeader})

    // get = get({ client, authHeader });
    // create = create({ client, authHeader });
    // update = update({ client, authHeader });
    // remove = remove({ client, authHeader });
    // removeAll = removeAll({ client, authHeader });
    // unremove = unremove({ client, authHeader });
  };
