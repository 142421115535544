import Product from "../../../../model/point-of-sale/product/product.model";

const parseToProduct = (pointOfSaleProduct) => {
  return new Product({
    tenantUid: pointOfSaleProduct.tenantUid,
    operationUid: pointOfSaleProduct.operationUid,
    id: pointOfSaleProduct.id,
    thirdPartyUid: pointOfSaleProduct.thirdPartyUid,
    category: pointOfSaleProduct.category,
    customizationItems: pointOfSaleProduct.customizationItems,
    recommendationTags: pointOfSaleProduct.recommendationTags,
    stockThreshold: pointOfSaleProduct.stockThreshold,
    size: pointOfSaleProduct.size,
    brandName: pointOfSaleProduct.brandName,
    brandImage: pointOfSaleProduct.brandImage,
    sku: pointOfSaleProduct.sku,
    label: pointOfSaleProduct.label.normalize('NFD')
      .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2")
      .normalize(),
    imageUrl: pointOfSaleProduct.imageUrl,
    thumbnailUrl: pointOfSaleProduct.thumbnailUrl,
    unit: pointOfSaleProduct.unit,
    itemViewType: pointOfSaleProduct.itemViewType,
    neverRecommend: pointOfSaleProduct.neverRecommend,
    recommendationLevel: pointOfSaleProduct.recommendationLevel,
    group: pointOfSaleProduct.group,
    itemType: pointOfSaleProduct.itemType,
    weight: pointOfSaleProduct.weight,
    outOfStock: pointOfSaleProduct.outOfStock,
    qtyPerUnit: pointOfSaleProduct.qtyPerUnit,
    sortOrder: pointOfSaleProduct.sortOrder,
    minQty: pointOfSaleProduct.minQty,
    price: pointOfSaleProduct.price,
    discountInPercent: pointOfSaleProduct.discountInPercent,
    strikedPrice: pointOfSaleProduct.strikedPrice,
    isPriceOverridden: pointOfSaleProduct.isPriceOverridden,
    discountLabel: pointOfSaleProduct.discountLabel,
    vatFree: pointOfSaleProduct.vatFree,
    requiresLegalAge: pointOfSaleProduct.requiresLegalAge,
    ratingEnabled: pointOfSaleProduct.ratingEnabled,
    hasSpecialInstructions: pointOfSaleProduct.hasSpecialInstructions,
    updatedOn: pointOfSaleProduct.updatedOn,
    createdOn: pointOfSaleProduct.createdOn,
    productCollections: pointOfSaleProduct.productCollections,
    withReminder: pointOfSaleProduct.withReminder,
    showWeight: pointOfSaleProduct.showWeight,
    isGiftWrapProduct: pointOfSaleProduct.isGiftWrapProduct,
    hasPriceRange: pointOfSaleProduct.hasPriceRange,
    hasSchedules: pointOfSaleProduct.hasSchedules,
    isPublished: pointOfSaleProduct.isPublished,
    isActive: pointOfSaleProduct.isActive,
    description: pointOfSaleProduct.description
  });
};

export default {
  parseToProduct,
};
